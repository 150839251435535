import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import menuIcon from '../media/icons/menu.png';

export const Header = ({
                           pages,
                           toggleMenu,
                           lang,
                           changeLanguage,
                           toggleLanguage
                       }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="Header">
            <div className="nav__logo">
                {t('title')}
            </div>

            <div className="nav__container">
                {pages.map((page, index) => {
                    // Для "home" перейдём на "/", иначе на "/work", "/projects" и т.д.
                    const path = page === 'home' ? '/' : `/${page}`;
                    const isActive = location.pathname === path;

                    return (
                        <button
                            className={`nav__item ${isActive ? 'nav__item-active' : ''}`}
                            key={index}
                            onClick={() => navigate(path)}
                        >
                            {t(page)}
                        </button>
                    );
                })}

                <div className="lang-swither__container">
                    <button className="nav__item lang" onClick={() => changeLanguage('ru')}>
                        ru
                    </button>
                    <label className="switch">
                        <input
                            type="checkbox"
                            role="switch"
                            id="langSwitch"
                            onChange={toggleLanguage}
                            checked={lang === 'en' || lang.startsWith('en')}
                        />
                        <span className="slider round"></span>
                    </label>
                    <button className="nav__item lang" onClick={() => changeLanguage('en')}>
                        en
                    </button>
                </div>
            </div>

            <button className="btn nav__button-expand" onClick={toggleMenu}>
                <img src={menuIcon} alt="menu" />
            </button>
        </div>
    );
};
