import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Header } from "./components/Header";
import { Home } from "./components/Home";
import { LeftMenu } from "./components/LeftMenu";
import { Footer } from "./components/Footer";
import { Work } from "./components/Work";
import { Projects } from "./components/Projects";
import "./i18n";
import i18n from "i18next";

function App() {
    // Массив страниц, который будем пробрасывать в Header и LeftMenu
    const pages = ["home", "work", "projects"];

    const [menuActive, setMenuActive] = useState(false);
    const [lang, setLang] = useState(i18n.language);

    const changeLanguage = (newLang) => {
        i18n.changeLanguage(newLang);
        setLang(newLang);
    };

    const toggleLanguage = (event) => {
        const newLang = event.target.checked ? "en" : "ru";
        changeLanguage(newLang);
    };

    const toggleMenu = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onCloseMenu = () => {
        setMenuActive(false);
    };

    return (
        <div className="App">
            <Router>
                <Header
                    pages={pages}
                    toggleMenu={toggleMenu}
                    lang={lang}
                    changeLanguage={changeLanguage}
                    toggleLanguage={toggleLanguage}
                />

                <LeftMenu
                    pages={pages}
                    active={menuActive}
                    setMenuActive={setMenuActive}
                    onClose={onCloseMenu}
                    lang={lang}
                    changeLanguage={changeLanguage}
                    toggleLanguage={toggleLanguage}
                />

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/work" element={<Work />} />
                    <Route path="/projects" element={<Projects />} />
                </Routes>

                <Footer />
            </Router>
        </div>
    );
}

export default App;
